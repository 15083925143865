import React, { useState } from 'react'
import "../Styles/MachineLog.css"
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import axios from "axios";
import { getUserDetails } from "../Utility/AsyncStorageData";
import * as FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';
import { showToast } from "../WebAppComponents/Toast"
import * as XLSX from "xlsx";
import Modal from "../WebAppComponents/Modal";


function Report(props) {
    // console.log(props.deviceData)
    let navigate = useNavigate();
    // var Logs = getSensorLog();
    var userDetails = getUserDetails();
    var reportName = props.reportName ?? userDetails.comp_name
    // console.log("logs", userDetails)

    const [error, setError] = React.useState('');
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'days').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionList, setOptionList] = useState();
    const [resultFilter, setResultFilter] = useState([])
    const [excelData, setExcelData] = useState([])
    const [previewReportState, setPreviewReportState] = useState(false)
    const [productList, setProductList] = React.useState([])
    const [productFilter, setProductFilter] = useState([]);
    const [categoryList, setCategoryList] = React.useState([])
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [reasonList, setReasonList] = React.useState([])
    const [reasonFilter, setReasonFilter] = useState([]);
    const [buttonflag, setButtonflag] = useState(false);

    const resultFilterOption = [
        { value: "Time Based Report", label: "Time Based Report" },
        { value: "Hour Based Report", label: "Hour Based Report" },
        { value: "Halt Based Report", label: "Halt Based Report" },
        { value: "Shift Halt Based Report", label: "Shift Halt Based Report" },
        { value: "Product Based Report", label: "Product Based Report" }

    ]
    const excelBlobData = React.useRef(undefined); // Use ref to store interval ID
    const maxCell = React.useRef(0); // Use ref to store interval ID

    // const [reportSensorList, setReportSensorList]= useState([])

    function handleSelect(data) {
        console.log("setSelectedOptions****************", data)
        setSelectedOptions(data);
    }

    function handleSelect1(data) {
        console.log("setResultFilter****************", data)
        setResultFilter(data);
    }

    function handleSelectProductCategory(data) {
        console.log("setProductCategoryFilter****************", data)
        setCategoryFilter(data);
        handleproductList(data.length ? data.map(item => item.label).join(", ") : undefined)
    }

    function handleSelectProduct(data) {
        console.log("setProductFilter****************", data)
        setProductFilter(data);
    }

    function handleSelectReason(data) {
        console.log("setReasonFilter****************", data)
        setReasonFilter(data);
    }

    const handleDateFrom = (changedDate) => {
        setFromDate(moment(changedDate).format('YYYY-MM-DD'))
        // date1.current = changedDate
        // console.log("Date From:", moment(changedDate).format('YYYY-MM-DD'));
    }

    const handleDateTo = (changedDate) => {
        setToDate(moment(changedDate).format('YYYY-MM-DD'))
        // date1.current = changedDate
        // console.log("Date to:", moment(changedDate).format('YYYY-MM-DD'));
    }

    const selectDeviceWiseMachines = async () => {
        let options = []
        setOptionList(options)

        try {
            console.log('*********** fetching DeviceWiseMachines list api ***********');

            let url = process.env.REACT_APP_BASE_URL + '/sensor/getSensorList';
            let body = {
                device_id: props.deviceData,
            };

            // console.log(" fetching DeviceWiseMachines list body", body)
            await axios
                .post(url, body, {
                    headers: {
                        authorization: `${userDetails.access_token}`,
                    },
                })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.statusCode === 0) {
                            for (let key of response.data.result)
                                options.push({ value: key._id, label: key.sensor_name })
                            setOptionList(options)
                        }

                        // console.log('***********  fetching DeviceWiseMachines list ***********', response.data.result);
                    }
                })
                .catch(error => {
                    console.log("ERROR", error)
                    if (error.response.status === 403) {
                        navigate("/");
                    }
                    setError('ERROR');
                    setTimeout(() => errortimer(), 2000);
                });
            // setLoading(false);

        } catch (error) {
            console.log('error ', error);
            throw error;
        }

    }

    const handleCategoryList = async () => {
        try {
            var comp_num = userDetails.comp_num

            if (props.show == 5) {
                comp_num = props.company
            }
            if (props.show == 8) {
                comp_num = props.deviceData
            }
            let url = process.env.REACT_APP_BASE_URL + "/ws/listOfProductCategory";
            let body = {
                comp_num: comp_num
            }
            
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        console.log("************listOfProductCategory************ ", response.data)
                        let temp = []
                        for(let key of response.data.list)
                        {
                            temp.push({ value: key.id, label: key.product_type })
                        }
                        setCategoryList(temp)

                    }
                    else if (response.data.statusCode === 1) {
                        showToast(response.data.msg, 3000, "red");
                    }


                    else {

                        showToast(response.data.msg, 3000, "red");
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }

            }).catch(error => {
                console.log("error: ", error);
                showToast("Slow or no internet connected. Please check your internet connection.", 3000, "red");

                // Alert.alert("ERROR")

                return
            })
        } catch (error) {
            console.log("error1 ", error)
            showToast(error, 3000, "red");
            return
        }
    }

    const handleproductList = async (product_type) => {
        try {
            setProductList([])
            var comp_num = userDetails.comp_num

            if (props.show == 5) {
                comp_num = props.company
            }
            if (props.show == 8) {
                comp_num = props.deviceData
            }
            let url = process.env.REACT_APP_BASE_URL + "/ws/listProductPart";
            let body = {
                comp_num: comp_num,
                product_type
            }
            
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        console.log("************companyWiseDeviceList************ ", response.data)
                        let temp = []
                        for(let key of response.data.list)
                        {
                            temp.push({ value: key.id, label: key.part })
                        }
                        setProductList(temp)

                    }
                    else if (response.data.statusCode === 1) {
                        showToast(response.data.msg, 3000, "red");
                    }


                    else {

                        // showToast(response.data.msg, 3000, "red");
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }

            }).catch(error => {
                console.log("error: ", error);
                showToast("Slow or no internet connected. Please check your internet connection.", 3000, "red");

                // Alert.alert("ERROR")

                return
            })
        } catch (error) {
            console.log("error1 ", error)
            showToast(error, 3000, "red");
            return
        }
    }

  const handleReasonList = async () => {
    try {

        var comp_num = userDetails.comp_num

        if (props.show == 5) {
            comp_num = props.company
        }
        if (props.show == 8) {
            comp_num = props.deviceData
        }
      let url = process.env.REACT_APP_BASE_URL + "/shift/machineHaltReason";
      let body = {
        comp_num: comp_num,
      }
      // console.log("************companyWiseDeviceList************ ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            // console.log("************companyWiseDeviceList************ ", response.data.data)
            let temp = []
            for(let key of response.data.data)
            {
                temp.push({ value: key.id, label: key.reason })
            }
            setReasonList(temp)
          }
          else if (response.data.statusCode === 1) {
            showToast(response.data.msg, 3000, "red");
          }


          else {

            showToast(response.data.msg, 3000, "red");
            console.log(" companyWiseDeviceList error: ", response.data.msg);
          }
        }


        else if (response.status == 403) {
          navigate("/");
        }

      }).catch(error => {
        console.log("error: ", error);
        showToast("Slow or no internet connected. Please check your internet connection.", 3000, "red");
       
        // Alert.alert("ERROR")

        return
      })
    } catch (error) {
      console.log("error1 ", error)
      showToast(error, 3000, "red");
      return
    }
  }

    const showDeviceMachineId = () => {
        if (props.show == 1 || props.show == 5) {
            return (
                // <h1>sdfghyu</h1>
                <div className="dropdown" style={{ marginTop: "10px" }}>
                    {/* <h2 style={{marginBottom: "10px", fontSize:"15px"}}>Select Machines</h2> */}
                    <div className="dropdown-container">
                        <Select
                            options={optionList}
                            placeholder="Select Machines"
                            value={selectedOptions}
                            onChange={handleSelect}
                            isMulti
                        />
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }

    const showCategoryList = () => {
        if (resultFilter.some((item) => item.label === "Product Based Report")) {
            return (
                <div className="dropdown" style={{ marginTop: "10px" }}>
                    <div className="dropdown-container">
                        <Select
                            options={categoryList}
                            placeholder="Select Product Type"
                            value={categoryFilter}
                            onChange={handleSelectProductCategory}
                            isMulti
                        />
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }

    const showProductList = () => {
        if (resultFilter.some((item) => item.label === "Product Based Report")) {
            return (
                <div className="dropdown" style={{ marginTop: "10px" }}>
                    <div className="dropdown-container">
                        <Select
                            options={productList}
                            placeholder="Select Product"
                            value={productFilter}
                            onChange={handleSelectProduct}
                            isMulti
                        />
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }

    const showReasonList = () => {
        if (resultFilter.some((item) => item.label === "Halt Based Report")) {
            return (
                <div className="dropdown" style={{ marginTop: "10px" }}>
                    <div className="dropdown-container">
                        <Select
                            options={reasonList}
                            placeholder="Select Halt Reason"
                            value={reasonFilter}
                            onChange={handleSelectReason}
                            isMulti
                        />
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }

    const viewExcel = async () => {
        // console.log('*********** reportDownload ***********', fromDate, toDate);
        maxCell.current = 0 
        var device_id = 0
        var machine_id = 0
        var comp_num = userDetails.comp_num

        if (props.show == 5) {
            comp_num = props.company
        }

        if (props.show == 0) {
            device_id = props.deviceData.device_id
            machine_id = props.deviceData.machine_id ?? undefined
        }

        else if (props.show == 2) {
            device_id = props.device_id
            machine_id = props.machine_id ?? undefined
        }

        else if (props.show == 4) {
            machine_id = props.deviceData
        }
        else if (props.show == 8) {
            comp_num = props.deviceData
            device_id = undefined
            machine_id = undefined
        }


        else {
            device_id = props.deviceData
            let temp = ""
            if (selectedOptions.length) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i == selectedOptions.length - 1) {
                        temp = temp + selectedOptions[i].value
                    }
                    else {
                        temp = temp + selectedOptions[i].value + ","
                    }
                }
            }
            else {
                temp = undefined
            }
            machine_id = temp
        }
        try {
            const filterValue = resultFilter.map(option => option.value);

            let url = process.env.REACT_APP_BASE_URL + "/reports/machineWiseReports";
            let body = {
                comp_num: comp_num,
                from: fromDate,
                to: toDate,
                download: "1",
                device_id: device_id,
                machine_id: machine_id,
                sheet_filter: filterValue.length ? filterValue : undefined,
                product_type : categoryFilter.length ? categoryFilter.map(item => item.label).join(", ") : undefined,
                product : productFilter.length ? productFilter.map(item => item.label).join(", ") : undefined,
                halt_reason : reasonFilter.length ? reasonFilter.map(item => item.label).join(", ") : undefined,
            };
            console.log('*********** ***********', body);
            const response = await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.token}`
                }, responseType: 'blob'
            })

            setButtonflag(false)

            const isJson = response.headers['content-type'].includes('application/json');
            if (isJson) {
                const text = await response.data.text();
                const json = JSON.parse(text);
                console.error("Error:", json.msg);
                showToast(json.msg, 5000, "red");
                return;
            }


            excelBlobData.current = response.data;

            const arrayBuffer = await response.data.arrayBuffer();

            // Read the Excel file
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            // Get first sheet
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const styledData = sheetData.map((row, rowIndex) =>

                row.map((cell, cellIndex) => {
                    const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: cellIndex });
                    const cellInfo = sheet[cellRef] || {};
                    if (maxCell.current < cellIndex) maxCell.current = cellIndex
                    return {

                        value: cell,
                        style: cellInfo.s || {}, // This contains style information
                    };
                })
            );

            console.log("sheetData ", styledData)

            setExcelData(styledData)
            if(styledData.length != 0){
                setPreviewReportState(true)
            }
            else{
                
                showToast("report is empty", 3000, "red");
            }

            


        } catch (error) {
            console.log('error ', error);
            if (error.response.status === 403) {
                navigate("/");
            }
            // props.sendDataToParent('close report')
            throw error;
        }
    }

    const downloadBlobExcel = async () => {
        try {

            var blob = new Blob([excelBlobData.current], {
                type: 'application/vnd.ms-excel',
            });
            let fileDate = new Date()
            var name = `/${reportName}_Machine_Report_${fileDate.getDate()}-${fileDate.getMonth() + 1}-${fileDate.getFullYear()}.xlsx`;
            FileSaver.saveAs(blob, name);
            excelBlobData.current = undefined;
            props.sendDataToParent('close report')
            showToast("Download Report successfully", 3000, "green");

        } catch (error) {
            console.log('error ', error);
            if (error.response.status === 403) {
                navigate("/");
            }
            props.sendDataToParent('close report')
            throw error;
        }
    }


    const downloadExcel = async (mailFlag) => {

        // console.log('*********** reportDownload ***********', fromDate, toDate);
        var device_id = 0
        var machine_id = 0
        var comp_num = userDetails.comp_num

        if (props.show == 5) {
            comp_num = props.company
        }

        if (props.show == 0) {
            device_id = props.deviceData.device_id
            machine_id = props.deviceData.machine_id ?? undefined
        }

        else if (props.show == 2) {
            device_id = props.device_id
            machine_id = props.machine_id ?? undefined
        }

        else if (props.show == 4) {
            machine_id = props.deviceData
        }
        else if (props.show == 8) {
            comp_num = props.deviceData
            device_id = undefined
            machine_id = undefined
        }


        else {
            device_id = props.deviceData
            let temp = ""
            if (selectedOptions.length) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i == selectedOptions.length - 1) {
                        temp = temp + selectedOptions[i].value
                    }
                    else {
                        temp = temp + selectedOptions[i].value + ","
                    }
                }
            }
            else {
                temp = undefined
            }
            machine_id = temp
        }
        try {
            const filterValue = resultFilter.map(option => option.value);

            let url = process.env.REACT_APP_BASE_URL + "/reports/machineWiseReports";
            let body = {
                comp_num: comp_num,
                from: fromDate,
                to: toDate,
                download: mailFlag == true ? undefined : "1",
                device_id: device_id,
                machine_id: machine_id,
                sheet_filter: filterValue.length ? filterValue : undefined,
                product_type : categoryFilter.length ? categoryFilter.map(item => item.label).join(", ") : undefined,
                product : productFilter.length ? productFilter.map(item => item.label).join(", ") : undefined,
                halt_reason : reasonFilter.length ? reasonFilter.map(item => item.label).join(", ") : undefined,
            };
            console.log('*********** ***********', body);
            const response = await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.token}`
                }, responseType: 'blob'
            })

            console.log("DOWNLOAD EXCEL")
            const isJson = response.headers['content-type'].includes('application/json');
            if (isJson) {
                const text = await response.data.text();
                const json = JSON.parse(text);
                console.error("Error:", json.msg);
                showToast(json.msg, 5000, "red");
                return;
            }

            if(mailFlag == true) return

            excelBlobData.current = response.data;
            await downloadBlobExcel();

        } catch (error) {
            console.log('error ', error);
            if (error.response.status === 403) {
                navigate("/");
            }
            props.sendDataToParent('close report')
            throw error;
        }
    }

    const downloadUserReport = async () => {
        console.log("download user report")
        
        try {

            let url = process.env.REACT_APP_BASE_URL + "/reports/userLoginWiseReport";
            let body = {
                from: fromDate,
                to: toDate
            };
            // console.log('*********** ***********', body);
            const response = await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.token}`
                }, responseType: 'blob'
            })
            var blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel',
            });
            let fileDate = moment().format('YYYY_MM_DD_HH_mm_ss')
            var name = `/UserLogin_Report_${fileDate}.xlsx`;
            // console.log("filename*****", name)
            FileSaver.saveAs(blob, name);
            // console.log("blob*****", blob)
            props.sendDataToParent('close report')



        } catch (error) {
            console.log('error ', error);
            if (error.response.status == 403) {
                navigate("/");
            }
            props.sendDataToParent('close report')
            throw error;
        }
    }

    const viewReport = async() => {
        setButtonflag(true)
        showToast("Report will be display shortly.", 3000, "green");
        await viewExcel()
    }

    const downloadReport = () => {
        downloadExcel()
        props.sendDataToParent('close report')
        showToast("Report will be download shortly.", 3000, "green");
    }


    const sendMail = () => {
        downloadExcel(true)
        props.sendDataToParent('close report')
        showToast("Report will be sent to you on your registered email address", 3000, "green");
    }

    const errortimer = () => {
        setError(undefined)
    }

    React.useEffect(() => {
        if (props.show == 1 || props.show == 5) {
            // console.log("sdfghj")
            selectDeviceWiseMachines()
        }
        if(props.show != 7)
        {
            handleCategoryList()
            handleproductList()
            handleReasonList()
        }
    }, []);


    return (
        <>
            <div style={{ overflowY: "visible", height: "fit-content" }}>
                <div>
                    <DatePicker
                        selected={fromDate}
                        placeholderText='Select From Data'
                        onChange={handleDateFrom}
                        className="custom-datepicker-report"
                        dateFormat="YYYY/MM/dd"
                        maxDate={new Date()}
                    />
                </div>

                <div>
                    <DatePicker
                        selected={toDate}
                        placeholderText='Select To Data'
                        onChange={handleDateTo}
                        className="custom-datepicker-report"
                        dateFormat="YYYY/MM/dd"
                        maxDate={new Date()}
                        minDate={fromDate}
                    />
                </div>

                {showDeviceMachineId()}
                {showCategoryList()}
                {showProductList()}
                {showReasonList()}

                {props.show != 7 && (<div className="dropdown-container" style={{ marginTop: 10 }}>
                    <Select
                        options={resultFilterOption}
                        placeholder="Select Report Format"
                        value={resultFilter}
                        onChange={handleSelect1}
                        isMulti
                    />
                </div>)}


                <div className='buttons'>
                    <button disabled={buttonflag} style={{ display: resultFilter.length == 1 ? "block" : "transparent" }} onClick={props.show == 7 ? downloadUserReport : viewReport}>Preview Excel</button>
                    <button disabled={buttonflag} onClick={props.show == 7 ? downloadUserReport : downloadReport}>Download Excel</button>
                    {props.show == 7 ? <></> : <button  disabled={buttonflag} onClick={sendMail}>Send Via Mail</button>}
                </div>

                {error ? <p>{error}</p> : <></>}

                {/* {excelData.length > 0 && (
                <div>
                    <button onClick={downloadBlobExcel}>Download</button>
                    <table border="1">
                        <tbody>
                            {excelData.map((row, rowIndex) => (
                                <>
                                    {rowIndex > 4 ?
                                        <tr key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <td
                                                    key={cellIndex}
                                                    style={{
                                                        fontWeight: cell.style?.bold ? "bold" : "normal",
                                                        backgroundColor: cell.style?.fgColor ? `#${cell.style.fgColor}` : "white",
                                                    }}
                                                >
                                                    {cell.value}
                                                </td>
                                            ))}
                                        </tr>
                                        :
                                        <tr key={rowIndex} style={{
                                            fontWeight: row.style?.bold ? "bold" : "normal",
                                            backgroundColor: row.style?.fgColor ? `#${row.style.fgColor}` : "white",
                                            textAlign: 'center'
                                        }}>
                                            <th colspan={maxCell.current+1}>
                                            {`${excelData[rowIndex].length ? excelData[rowIndex][0].value : ""}`}
                                            </th>
                                        </tr>
                                    }
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            )} */}

            </div>
            <Modal isOpen={previewReportState}>
                {excelData.length > 0 && (
                    <div style={{overflow:"scroll", scrollbarWidth:'thin', maxHeight: "90vh"}}>
                        <div className='buttons' style={{marginBottom: 10}}>
                            <button onClick={downloadBlobExcel}>Download</button>
                            <button onClick={()=> {setPreviewReportState(false)}}>X</button>
                        </div>
                        <table border="1">
                            <tbody>
                                {excelData.map((row, rowIndex) => (
                                    <>
                                        {rowIndex > 4 ?
                                            <tr key={rowIndex}>
                                                {row.map((cell, cellIndex) => (
                                                    <td
                                                        key={cellIndex}
                                                        style={{
                                                            fontWeight: cell.style?.bold ? "bold" : "normal",
                                                            backgroundColor: cell.style?.fgColor ? `#${cell.style.fgColor}` : "white",
                                                        }}
                                                    >
                                                        {cell.value}
                                                    </td>
                                                ))}
                                            </tr>
                                            :
                                            <tr key={rowIndex} style={{
                                                fontWeight: row.style?.bold ? "bold" : "normal",
                                                backgroundColor: row.style?.fgColor ? `#${row.style.fgColor}` : "white",
                                                textAlign: 'center'
                                            }}>
                                                <th colspan={maxCell.current + 1}>
                                                    {`${excelData[rowIndex].length ? excelData[rowIndex][0].value : ""}`}
                                                </th>
                                            </tr>
                                        }
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Modal>
        </>


    )
}

export default Report