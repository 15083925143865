import React from 'react'
// import { useNavigate } from 'react-router-dom';
import "../Styles/WebLogin.css"
import axios from "axios";
import { setUserDetails, getUserDetails, setActive, removeUserDetails } from "../Utility/AsyncStorageData"
import Version from '../Utility/Version';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import flovation from "../Assets/new_logo.png"
import { showToast } from './Toast';


function WebLogin() {

    let navigate = useNavigate();
    let pervUser = getUserDetails()
    // console.log("pervUser ", pervUser)

    const [load, setLoad] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [otpState, setOtpState] = React.useState(true);
    const [newUser, setNewUser] = React.useState(null);




    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: {
            width: '40px',
            height: '40px',
            fontSize: '20px',
            margin: '0 5px',
            textAlign: 'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
        }
    };

    const handleInputChange = (event) => {
        setEmail(event.target.value)
    };


    const handleLogin = async () => {

        try {
            var login_id = pervUser ? (pervUser.contact != "NA" ? pervUser.contact : pervUser.email) : undefined
            const userAgent = navigator.userAgent;
            // console.log(login_id);
            if (email || login_id) {

                let url = process.env.REACT_APP_BASE_URL + "/api/userLogin";
                let body = {
                    email: (login_id ?? email.replace(/\s+/g, "").toLowerCase()),
                    password: userAgent,
                    otp: otp.length ? otp : undefined
                }
                setEmail(body.email)
                // console.log("--------------------userLogin api------------------------", body)
                await axios.post(url, body).then(response => {

                    if (response.status === 200) {


                        // console.log("response: ", response.data.statusCode);
                        // setEmailAndPassword((email.replace(/\s+/g, "").toLowerCase()) || (email1.replace(/\s+/g, "").toLowerCase()), DeviceInfo.getDeviceId())

                        // console.log("user token ", response.data.result)
                        if (response.data.statusCode === 0) { ///for success
                            setUserDetails(JSON.stringify(response.data.result));
                            console.log("user details", response.data.result)
                            setActive(JSON.stringify("Dashboard"))
                            setOtp("")
                            setEmail("")
                            setNewUser(false)
                            setOtpState(true)

                            if (newUser === true) {
                                // console.log("new user")
                                navigate('/companyForm');
                            }
                            else {

                                setOtpState(true)
                                // console.log("Login succssefully amd navigate acc to role")


                                if (response.data.result.role_id === "A") {
                                    //   console.log(response.data.result.role_id)
                                    navigate("/webAdmin");
                                }
                                else if (response.data.result.role_id === "C") {
                                    //   console.log(response.data.result.role_id)
                                    navigate("/controllerDashboard");
                                }
                                else {
                                    //   console.log(response.data.result.role_id)
                                    navigate("/owner");
                                }
                            }
                        }
                        else if (response.data.statusCode === 2) { //incorrect password

                            showToast(response.data.msg, 3000, "red");
                            setNewUser(false)
                            setOtpState(false);
                            setLoad(false)
                            return
                        }
                        else if (response.data.statusCode === 1) { //for new user

                            setOtpState(false);
                            showToast(response.data.msg, 3000, "red");
                            setNewUser(true)
                            setLoad(false)
                            return
                        }
                        else {
                            setOtp("")
                            setEmail("")
                            showToast(response.data.msg, 3000, "red");
                            return
                        }
                    }
                    else if (response.status === 401) {
                        showToast(response.data.msg, 3000, "red");
                        navigate("/webLogin");
                        return
                    }
                    else {

                        showToast(response.data.msg, 3000, "red");
                        console.log("error in userLogin: ", response.data.msg);
                    }
                }).catch(error => {
                    showToast(error, 3000, "red");
                    console.log("error: ", error);
                    // if (error.response.status == 403) {
                    //     navigate("/");
                    // }
                    return
                })
            }
            setLoad(false)
        } catch (error) {
            console.log("error1 ", error)
            throw error;
        }

    };


    const backToLogin = () => {
        removeUserDetails()
        localStorage.clear();
        setOtpState(true)
        setEmail("")
        setOtp("")
    }

    React.useEffect(() => {
        handleLogin();
    }, []);


    return (
        <div>
            {load != 0 ?
                <div className='load'> <img src="https://tmmapi.9930i.com/sm_loader" alt="Computer man" style={{ height: "150px" }} />
                </div> :

                <div className="login-wrapper">
                    <div className="login-container">
                        <div className="right-section">
                            <div className="form-container">

                                <img src={flovation} alt="A descriptive text" style={{ width: "100px", height: "auto" }} />
                                <h2>Welcome to SuperManager</h2>
                                <p>Sign in to continue</p>
                                <div>
                                    {otpState ?
                                        <div>
                                            <label className="label">Email / Phone no.</label>
                                            <input type="text"
                                                name="email"
                                                placeholder="Email / Phone no."
                                                value={email}
                                                onChange={handleInputChange}
                                                className="form-input"
                                                required
                                            />
                                        </div>
                                        :
                                        <div>
                                            <p>Enter your One Time password Sent to {email}</p>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={4}
                                                renderSeparator={<span> </span>}
                                                renderInput={(props) => <input {...props} />}
                                                containerStyle={styles.container}
                                                inputStyle={styles.input}
                                            />
                                            <div className='clearOTP'>
                                                <h4 onClick={() => { setOtp(null) }}>clear Otp</h4>
                                            </div>

                                            <div style={{ opacity: "0.8", border: "1px solid gray", padding: "2px", borderRadius: "10px", alignItems: "center", width: "90%", margin: "0 5%" }}
                                                onClick={backToLogin} >
                                                <p style={{ margin: "5px", fontSize: "13px" }}>Login with Another Email</p>
                                            </div>

                                        </div>
                                    }
                                    <button className="login-button" onClick={() => handleLogin()}>
                                        Login
                                    </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <p style={{position: "absolute", bottom: 0, textAlign: "center", color:"gray", fontSize: 10}}>Version {Version[0].ver}</p>
                                
                </div>
            }
        </div>
    )
}

export default WebLogin






