import React, { useRef, useEffect } from 'react';
import axios from "axios";
import "../Styles/TvDashboard.css"
import flovation from "../Assets/new_logo.png"
import { setTvDetails, getTvDetails, removeTvDetails, removeUserDetails } from "../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
var runtimeid = 0;
var intervalId = 0;
var statueBarInterval = 9;

function TvDashboard() {
  console.log("**************TvDashboard*****************")
  let navigate = useNavigate();

  var TvDetails = getTvDetails();
  const [machineData, setMachineData] = React.useState([]);
  const [machineStates, setMachineStates] = React.useState([]);
  const [error, setError] = React.useState(null);
  const gb_machine_id = useRef("");
  const [graph, setGraph] = React.useState([]);
  const [colorWidthData, setColorWidthData] = React.useState([]);
  const [internetSpeed, setInternetSpeed] = React.useState(0);


  const refreshAccessToken = () => {

    // console.log("refreshAccessToken");
    try {
      let url = process.env.REACT_APP_BASE_URL + "/tv/verifyTvCode";
      let body = {
        tv_code: TvDetails.tv_code,
      }

      // console.log("response: ", body, url);
      axios.post(url, body).then(response => {

        if (response.status === 200) {
          if (response.data.statusCode === 0) {
            // console.log("response: ", response.data.data);
            setTvDetails(JSON.stringify(response.data.data));
            TvDetails = getTvDetails();
          }
        }
        else {
          setError(response.data.msg);
          setTimeout(() => clearErrorMsg(), 3000)
        }
      })

    } catch (error) {
      console.log("ERROR");
      if (error.response.status === 403) {
        navigate("/");
      }
      setError("Network Error");
      setTimeout(() => clearErrorMsg(), 3000)

    }
  }

  const dashProgressBar = async () => {
    try {
      // console.log('*********** dashProgressBar api ***********');

      if (gb_machine_id.current.length !== 0) {

        let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
        let a = ""
        for (let key of gb_machine_id.current) {
          a = a + key.replace("'", "")
        }

        let body = {
          machine_ids: a
        };

        // console.log(" dashProgressBar body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${TvDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {
                // setMachineData(response.data.list)
                // setMachineStates(response.data)
                setColorWidthData(response.data.result)
                // console.log(response.data.result)
              } else {

                console.log("dashProgressBar api error", response.data.msg)
                setError("ERROR")
                // setTimeout(() => errortimer(), 2000);
              }
            }
            else if (response.status === 403) {
              // console.log("refreshAccessToken: ");
              refreshAccessToken();
              dashProgressBar();
            }
          })
          .catch(error => {
            // Alert.alert("ERROR")
            refreshAccessToken();
            if (error.response.status === 403) {
              navigate("/");
            }
            console.log("ERROR", error)
            setError('ERROR');
            setTimeout(() => clearErrorMsg(), 2000);
          });
        // setLoading(false);
      }

    } catch (error) {
      console.log('error ', error);
      setError('ERROR');
      setTimeout(() => clearErrorMsg(), 2000);
      // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
      throw error;
    }
  }

  const TvMachineAssignList = () => {

    console.log("TvMachineAssignList");
    try {
      let url = process.env.REACT_APP_BASE_URL + "/dashboard/tvDashMachineAssignList";
      let body = {
        dash_id: TvDetails.dash_id,
        comp_num: TvDetails.comp_num
      }

      // console.log("TvMachineAssignList body", body);

      axios.post(url, body, {
        headers: {
          'authorization': `${TvDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {
          if (response.data.statusCode === 0) {

            const machineIds = response.data.data.map(item => item.sensor_data._id);
            const machineIdsString = machineIds.map(item => `${item}`).join(', ');

            // console.log("machineIds===================", machineIdsString);

            const elements = machineIdsString.split(', ');
            gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

            // console.log(gb_machine_id.current)
            setGraph(response.data.data)
            Runtime()

          }

          else {
            console.log("response: ", response.data.msg);
            setError(response.data.msg);
            setTimeout(() => clearErrorMsg(), 3000)
          }
        }
        else if (response.status === 403) {
          // console.log("refreshAccessToken: ");
          refreshAccessToken();
          TvMachineAssignList();
        }

      }).catch(error => {
        if (error.response.status === 403) {
          navigate("/");
        }
        refreshAccessToken();
        Runtime();
      });

    } catch (error) {
      console.log("ERROR");

    }
  }

  const Runtime = async () => {
    try {
      console.log('*********** getMachineStatus api ***********');

      if (gb_machine_id.current.length !== 0) {


        let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
        let body = {
          // machineState: machineState,
          machine_id: gb_machine_id.current
        };

        // body.machine_id = elements.map(element => `'${element}'`).join(', ');
        // console.log(" getMachineStatus body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${TvDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {

                setMachineData(response.data.list)
                setMachineStates(response.data)

                statueBarInterval = statueBarInterval + 1;
                if (statueBarInterval >= 10) {

                  // console.log("statueBarInterval", statueBarInterval)
                  statueBarInterval = 0;
                  dashProgressBar()
                }

              }

              else {

                console.log("getMachineStatus api error", response.data.msg)
                setError("ERROR")
                // setTimeout(() => errortimer(), 2000);
              }
            }
            else if (response.status === 403) {
              console.log("refreshAccessToken: ");
              if (error.response.status === 403) {
                navigate("/");
              }
              refreshAccessToken();
              Runtime();
            }
          })
          .catch(error => {
            // Alert.alert("ERROR")
            refreshAccessToken();
            Runtime();
            console.log("ERROR", error)
            setError('ERROR');
            setTimeout(() => clearErrorMsg(), 2000);
          });
        // setLoading(false);
      }
      runtimeid = setTimeout(() => { Runtime(); }, 60000)

    } catch (error) {
      console.log('error ', error);
      setError('ERROR');
      setTimeout(() => clearErrorMsg(), 2000);
      runtimeid = setTimeout(() => { Runtime() }, 60000)
      throw error;
    }
  }

  const machineTime = (mech_id, data) => {
    if (machineData[mech_id]) {
      // console.log("machineTime" , machineData[mech_id].machine_current_status, data)
      if (machineData[mech_id].machine_current_status == "D") {
        return ("#E9E9E9") //gray
      }
      else {
        if (machineData[mech_id].efficiency >= data.Y) {
          return "#F1F9EE" //green
        }
        else if (machineData[mech_id].efficiency >= data.R) {
          return "#FEFBED" // orange
        }
        else {
          return "#FBF3F3" // red
        }
      }
    }
    else {
      return ("#D3D3D3") //gray
    }
  }

  const machineStatus = (mech_id) => {
    // console.log(mech_id, machineData[mech_id])
    if (machineData[mech_id].machine_current_status === "D") {
      return (
        <div style={{ color: "blue", backgroundColor: 'blue', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
      )
    }
    else {
      if (machineData[mech_id].machine_current_state === "0") {
        return (
          <div style={{ color: "red", backgroundColor: 'red', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
      else if (machineData[mech_id].machine_current_state === "1") {
        return (
          <div style={{ color: "orange", backgroundColor: 'orange', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
      else {
        return (
          <div style={{ color: "green", backgroundColor: 'green', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
    }


  }

  const showShift = (machine_id) => {
    const time = moment(machineData[machine_id].start_time, 'HH:mm:ss')
    const start = time.format('HH:mm');


    const time1 = moment(machineData[machine_id].end_time, 'HH:mm:ss')
    const end = time1.format('HH:mm');

    return (start + " - " + end)

  }

  const PrevshowShift = (machine_id) => {
    const time = moment(machineData[machine_id].previousData.shift_start_time)
    const start = time.format('HH:mm');


    const time1 = moment(machineData[machine_id].previousData.shift_end_time)
    const end = time1.format('HH:mm');

    // console.log("start time - end time", start,end, time, time1)
    return (start + " - " + end)

  }

  const measureSpeed = async () => {
    const imageUrl = "https://upload.wikimedia.org/wikipedia/commons/3/3a/Bucharest_Panorama_1.jpg";
    const fileSizeInMB = 5;

    const startTime = performance.now();
    try {
      await fetch(imageUrl, { method: "GET", cache: "no-cache" });
      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      const speedMbps = (fileSizeInMB / durationInSeconds) * 8; // Convert MBps to Mbps
      setInternetSpeed(`${speedMbps.toFixed(2)}`);
    } catch (error) {
      setInternetSpeed(0);
    }
  };


  const updateOnTimeInSec = () => {
    measureSpeed()

    if (Object.keys(machineData).length) {
      setMachineData((prevData) => {
        const updatedData = { ...prevData };

        for (let key of graph) {
          if (updatedData[key.sensor_data._id].machine_current_state === '2') {
            const timeString = updatedData[key.sensor_data._id].on_time;
            const timeObject = moment(`2000-01-01 ${timeString}`, 'YYYY-MM-DD HH:mm:ss');

            // Add one second to the time
            timeObject.add(1, 'second');

            // Format the updated time string
            const newTimeString = timeObject.format('HH:mm:ss');

            // Create a new object for the specific item and update the on_time property
            updatedData[key.sensor_data._id] = {
              ...updatedData[key.sensor_data._id],
              on_time: newTimeString,
            };
          }
        }

        return updatedData;
      });
    }

  }

  const borderRadius = (serialNo, machineId) => {
    // console.log(serialNo, machineId ,colorWidthData[machineId].length )
    if (colorWidthData[machineId].length == 1) {
      return "10px"
    }
    else if (serialNo == colorWidthData[machineId].length - 1) {
      // console.log(colorWidthData[machineId])
      return " 0 10px 10px 0 "
    }
    else if (serialNo == 0) {
      return " 10px 0 0 10px"
    }


  }

  const LogOut = () => {
    removeTvDetails()
    removeUserDetails()
    console.log("logout")
    navigate("/");
  }

  clearInterval(intervalId);
  intervalId = setInterval(() => {
    updateOnTimeInSec();
  }, 1000);



  const clearErrorMsg = () => {
    setError(undefined);
  }



  useEffect(() => {
    TvMachineAssignList()
    return () => {
      clearInterval(runtimeid);
    };
  }, []);

  return (
    <div className='TvDashboard'>
      <div className='Header'>
        <div style={{ width: "70px" }}>
          <img style={{ width: "60px" }} src={flovation} alt="flovation_Logo" />
        </div>
        <div style={{ width: "77%" }} className='com_name'>
          <h3>SuperManager</h3>
          <h4>{TvDetails.comp_name}</h4>
        </div>

        <div>
          <h6 style={{margin:5, textAlign: "right", width: '100%'}}> Net Speed: {internetSpeed}Mbps</h6>

          <div className="stats" style={{ alignItems: 'center' }}>
            <h2 style={{ backgroundColor: "lightGreen", border: "2px solid green" }}>{machineStates.on_load ?? 0}</h2>
            <h2 style={{ backgroundColor: "rgb(242, 187, 84)", border: "2px solid orange" }}>{machineStates.free_load ?? 0}</h2>
            <h2 style={{ backgroundColor: "rgb(245, 143, 143)", border: "2px solid red" }}>{machineStates.zero_load ?? 0}</h2>
            <div style={{ width: "5%", marginLeft: 5 }} className='signOut'> <i className="fa fa-sign-out" onClick={LogOut} ></i></div>
          </div>

          {/* <div style={{ width: "5%" }} className='signOut'>
          <i className="fa fa-sign-out" onClick={LogOut} ></i>
        </div> */}
        </div>
      </div>

      <div className='com_name_mobile'>
        <h3>SuperManager</h3>
        <h4>{TvDetails.comp_name}</h4>
      </div>

      <div className='machines'>

        {graph.length ?
          <div style={{
            width: "100%",
            flexWrap: "wrap",
            display: "inline-flex",
            justifyContent: "space-between",
            height: "100%",
          }}>
            {((Object.keys(machineData).length)) ?
              graph.map((item, index) => {
                return (
                  <div key={index} className='machine_card' style={{ backgroundColor: machineTime(item.sensor_data._id, item.sensor_data.alarm_color) }}>
                    {machineData[item.sensor_data._id] !== undefined ?
                      (
                        ((machineData[item.sensor_data._id].shift_status) ?
                          <div>
                            <div style={{ paddingHorizontal: "2%" }}>
                              {/* first row */}
                              <div style={{ paddingTop: 5, marginLeft: 5 }} className='inLine'>
                                {((Object.keys(machineData).length) !== 0 && (machineData[item.sensor_data._id])) ? (machineStatus(item.sensor_data._id)) : ("")}

                                <div style={{ marginLeft: 20 }}>
                                  <h4 className='TextMachName'>{item.sensor_data.sensor_name}</h4>
                                  <h5 className='TextDevName'>{item.device_name}</h5>
                                </div>
                              </div>

                              {/* 2nd row */}

                              {item.sensor_data.counter_flag === "Y" ?
                                <div style={{ justifyContent: "space-between" }} className='inLine'>
                                  {machineData.hasOwnProperty(item.sensor_data._id) ?
                                    <h3 className='machineTimeText'>{(machineData[item.sensor_data._id].on_time)}</h3>
                                    :
                                    <h3 className='machineTimeText'>--:--</h3>
                                  }

                                  <h4 style={{ width: "40%", flexWrap: 'wrap' }} className='machineTimeText1'>{machineData[item.sensor_data._id].sensor_count} {machineData[item.sensor_data._id].counterUnit}</h4>

                                  <h4 className='machineEff'>
                                    {((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].efficiency) : ("NA")}%
                                  </h4>

                                </div>
                                :
                                <div style={{ justifyContent: "space-between" }} className='inLine'>

                                  {machineData.hasOwnProperty(item.sensor_data._id) ?
                                    <h3 className='machineTimeText'>{(machineData[item.sensor_data._id].on_time)}</h3>
                                    :
                                    <h3 className='machineTimeText'>--:--</h3>
                                  }
                                  <h4 className='machineEff'>
                                    {((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].efficiency) : ("NA")}%
                                  </h4>
                                </div>
                              }

                              <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                                {(Object.keys(colorWidthData).length && (colorWidthData[item.sensor_data._id])) ?
                                  colorWidthData[item.sensor_data._id].map(({ color, width }, index) => (
                                    <div key={index} style={{ width, backgroundColor: color, borderRadius: (borderRadius(index, item.sensor_data._id)) }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                                  )) :
                                  <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                              </div>


                              {/* 3rd row */}

                              <div>
                                <div style={{ margin: "5px" }} className='inLine'>
                                  <h4 className='shiftTime'>Current Shift</h4>
                                  <h5 className="shiftTimedata" style={{ margin: "0px 10px", color: "#49454F" }}>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? showShift(item.sensor_data._id) : ("NA")} </h5>
                                </div>
                              </div>

                              <div style={{ justifyContent: "space-between", marginBottom: 10 }} className='inLine'>
                                <div style={{ marginHorizontal: 10 }}>
                                  {((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id]) && (machineData[item.sensor_data._id].previousData.machine_time_id !== 0)) ?
                                    <div>
                                      <h4 className='shiftTime'>Previous Shift</h4>
                                      <div className='inLine'>
                                        <h5 className="shiftTimedata" style={{ color: "#938F99" }}>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? PrevshowShift(item.sensor_data._id) : ("NA")}  /  </h5>
                                        <h5 className="shiftTimedata" style={{ color: "#938F99" }}>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].previousData.eff) : ("NA")} %</h5>
                                      </div>
                                    </div>
                                    :
                                    <></>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div style={{ width: "300px" }}>
                            <div style={{ width: "100%", flexDirection: 'row', alignItems: "center" }}>
                              <h4 style={{ width: "90%", fontSize: 21, fontFamily: "poppins, san-sarif", color: "black", textAlign: "center" }}>{item.sensor_name}</h4>


                            </div>
                            <div style={{
                              width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
                            }}>
                              <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955' }}>{item.device_name}</h4>
                              <div style={{
                                flexDirection: "row", alignItems: "center",
                              }}>
                                <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955', paddingRight: 10 }}> Current Shift Not Exist</h4>
                              </div>
                            </div>
                          </div>
                        )

                      ) :
                      (
                        <div style={{ width: "300px" }}>
                          <div style={{ width: "100%", flexDirection: 'row', alignItems: "center" }}>
                            <h4 style={{ width: "90%", fontSize: 21, fontFamily: "poppins, san-sarif", color: "black", textAlign: "center" }}>{item.sensor_name}</h4>


                          </div>
                          <div style={{
                            width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
                          }}>
                            <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955' }}>{item.device_name}</h4>
                            <div style={{
                              flexDirection: "row", alignItems: "center",
                            }}>
                              <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955', paddingRight: 10 }}> Current Shift Not Exist</h4>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              })
              : <></>
            }


          </div>
          :
          <></>

        }

        {/* <p>{error}</p> */}

      </div>


    </div>
  )
}

export default TvDashboard