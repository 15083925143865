const Version = [
    {
        ver: "2.0.22",
        des: "product category added in report"
    },
    {
        ver: "2.0.21",
        des: "add scroll on report view"
    },
    {
        ver: "2.0.20",
        des: "add editand delete product, assign product to the machineand update that product in the machine log"
    },
    {
        ver: "2.0.19",
        des: "hide navbar in owner details page"
    },
    {
        ver: "2.0.18",
        des: "view details in owner page to show machine log page"
    },
    {
        ver: "2.0.17",
        des: "add net speed in tv dashboard"
    },
    {
        ver: "2.0.16",
        des: "add net speed in tv dashboard"
    },
    {
        ver: "2.0.15",
        des: "minore change in product lint in machine log"
    },
    {
        ver: "2.0.14",
        des: "change ui of footer in mobile view"
    },
    {
        ver: "2.0.13",
        des: "report particular reason and product wise and also comp num check remove from machinelog page"
    },
    {
        ver: "2.0.12",
        des: "report preview feature"
    },
    {
        ver: "2.0.11",
        des: "Add Report Format"
    },
    {
        ver: "2.0.10",
        des: "new logic to add product list in machine log "
    },
    {
        ver: "2.0.9",
        des: "Add counter in machine log page"
    },
    {
        ver: "2.0.8",
        des: "product list and edit add delete the product in web with comp num 267"
    },
    {
        ver: "2.0.7",
        des: "Owner and machine inchare page"
    },
    {
        ver: "2.0.6",
        des: "new ui red and blue"
    },
    {
        ver: "2.0.5",
        des: "Add gate value in temperature detail page"
    },
    {
        ver: "2.0.4",
        des: "show counter details and add temperature detail page with infinite scroll"
    },
    {
        ver: "2.0.3",
        des: "Temperature dashboard added on temp.flovation.in "
    },
    {
        ver: "2.0.1",
        des: "controller section added"
    },
    {
        ver: "1.0.1",
        des: " implement tvCode"
    }
]

export default Version;

//top is the latest one 