import React, { useState, useRef, useEffect } from "react";
import "../Styles/WebAdmin.css"
import "../Styles/TvDashboard.css"
import Navbar from "./Navbar";
import { getUserDetails, setSensorLog, setSensorOnTime, setStats } from "../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from "./Header";
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { removeUserDetails } from "../Utility/AsyncStorageData";
import Modal from "./Modal";
import Report from '../Utility/Report'

var runtimeid = 0
var statueBarInterval = 19;
var intervalId = 0;

function Owner() {

  var userDetails = getUserDetails();

  // console.log("**************WebAdmin*****************", userDetails)
  let navigate = useNavigate();


  const gb_machine_id = React.useRef("");
  const deviceIdsRef = React.useRef("");

  const [error, setError] = React.useState('');
  const [graph, setGraph] = React.useState([]);
  const [runningListLog, setRunningListLog] = React.useState({});
  const [runningStatus, setRunningStatus] = React.useState({});
  const [colorWidthData, setColorWidthData] = React.useState([]);
  const [downloadExcel, setDownloadExcel] = React.useState(false);
  // const [listStatus, setListStatus] = React.useState(null)



  const handleFetchMachineList = async () => {
    try {
      // console.log("*********** handleFetchMachineList ***********")


      // let url = process.env.REACT_APP_BASE_URL + "/sensor/getCompanyWiseSensorList";
      // let body = {
      //   comp_num: userDetails.comp_num
      // }
      // console.log("===============getCompanyWiseSensorList======================", body)

      let url = ""
      let body = ""

      if (userDetails.role_id == 'O') {
        url = process.env.REACT_APP_BASE_URL + "/sensor/getCompanyWiseSensorList";
        body = {
          comp_num: userDetails.comp_num
        }
      }
      else {
        url = process.env.REACT_APP_BASE_URL + "/api/machineAssignList";
        body = {
          user_num: userDetails.user_id,
          machine_id: String(userDetails.machine_id).slice(1, -1)
        }
      }
      console.log("===============getCompanyWiseSensorList======================", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            // setShowData(false);
            console.log("asdfgh", userDetails.role_id, response.data)
            let machineIds = ""

            if (userDetails.role_id == 'O') {
              setGraph(response.data.result)
              console.log("//////////////////////////", response.data.result)
              machineIds = response.data.result.map(item => item.machine_id);

              const uniqueDeviceIds = response.data.result.map(item => item.device_id);
              deviceIdsRef.current = Array.from(new Set(uniqueDeviceIds)).join(', ');


            }
            else {
              let x = response.data.data
              for (let i = 0; i < x.length; i++) {
                x[i].machine_id = x[i].sensor_data._id
              }

              // console.log("//////////////////////////", response.data.data.sensor_data)

              console.log(x)
              setGraph(x)
              machineIds = x.map(item => item.machine_id);

              const uniqueDeviceIds = response.data.data.map(item => item.device_id);
              deviceIdsRef.current = Array.from(new Set(uniqueDeviceIds)).join(', ');


            }

            const machineIdsString = machineIds.map(item => `${item}`).join(', ');

            const elements = machineIdsString.split(', ');
            gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

            console.log("//////////////////////////", gb_machine_id.current)

            // if (!e) {
            //   subscribeFCMTopic()
            // }

            Runtime()
// if()
            // showToast(response.data.msg, 3000, "red");
          }
          else {
            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            // setLoading(false);
            // setShowData(true);
            console.log("error in api", response.data.msg)
          }
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
          navigate("/");
        }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
        //         setLoading(false);
        //         return
      })
    } catch (error) {
      console.log("error ", error)
      //     setLoading(false);
      //     throw error;
    }

  }


  const Runtime = async () => {
    try {
      // console.log('*********** getMachineStatus api ***********');

      if (gb_machine_id.current.length != 0) {

        // console.log('*********** api hit ***********', d);

        let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
        let body = {
          // machineState: machineState,
          machine_id: gb_machine_id.current
        };

        // body.machine_id = elements.map(element => `'${element}'`).join(', ');
        // console.log(" getMachineStatus body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${userDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {

                setRunningListLog(response.data)
                console.log("asdfghj", response.data)
                setStats(JSON.stringify(response.data))

                setRunningStatus(response.data.list)


                // console.log("statueBarInterval1", statueBarInterval, response.data)
                statueBarInterval = statueBarInterval + 1;
                if (statueBarInterval >= 20) {

                  // console.log("statueBarInterval", statueBarInterval)
                  statueBarInterval = 0;
                  dashProgressBar()
                }

              }
              else if (response.data.statusCode === 2) {

                console.log("getMachineStatus api error", response.data.msg)
              }
              else {

                console.log("getMachineStatus api error", response.data.msg)
                setError("ERROR")
                setTimeout(() => errortimer(), 2000);
              }
            }
          })
          .catch(error => {
            // Alert.alert("ERROR")
            console.log("ERROR", error)
            if (error.response.status == 403) {
              navigate("/");
            }
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
          });
        // setLoading(false);
      }
      runtimeid = setTimeout(() => Runtime(), 30000)
    } catch (error) {
      console.log('error ', error);
      runtimeid = setTimeout(() => Runtime(), 30000)
      throw error;
    }
    // setLoading(false);
  }

  const dashProgressBar = async () => {
    try {
      // console.log('*********** dashProgressBar api ***********');

      if (gb_machine_id.current.length != 0) {

        let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
        let a = ""
        for (let key of gb_machine_id.current) {
          a = a + key.replace("'", "")
        }

        let body = {
          machine_ids: a
        };

        // console.log(" dashProgressBar body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${userDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {
                // setMachineData(response.data.list)
                // setMachineStates(response.data)
                setColorWidthData(response.data.result)
                // console.log(response.data.result)
              } else {

                console.log("dashProgressBar api error", response.data.msg)
                setError(response.data.msg)
                setTimeout(() => errortimer(), 2000);
              }
            }
            else if (response.status == 403) {
              // console.log("refreshAccessToken: ");
              // refreshAccessToken();
              dashProgressBar();
            }
          })
          .catch(error => {
            if (error.response.status == 403) {
              navigate("/");
            }
            console.log("ERROR", error)
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
          });
        // setLoading(false);
      }

    } catch (error) {
      console.log('error ', error);
      setError('ERROR');
      setTimeout(() => errortimer(), 2000);
      // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
      throw error;
    }
  }

  const borderRadius = (serialNo, machineId) => {
    // console.log(serialNo, machineId ,colorWidthData[machineId].length )
    if (colorWidthData[machineId].length == 1) {
      return "10px"
    }
    else if (serialNo == colorWidthData[machineId].length - 1) {
      // console.log(colorWidthData[machineId])
      return " 0 10px 10px 0 "
    }
    else if (serialNo == 0) {
      return " 10px 0 0 10px"
    }


  }

  const machineTime = (mech_id, data) => {
    if (runningStatus[mech_id]) {
      // console.log("machineTime", runningStatus[mech_id].efficiency, data.Y)
      if (runningStatus[mech_id].machine_current_status == "D") {
        return ("#E9E9E9") //gray
      }
      else {
        if (runningStatus[mech_id].efficiency >= data.Y) {
          return "#F1F9EE" //green
        }
        else if (runningStatus[mech_id].efficiency >= data.R) {
          return "#FEFBED" // orange
        }
        else {
          return "#FBF3F3" // red
        }
      }
    }
    else {
      return ("#D3D3D3") //gray
    }
  }

  const updateOnTimeInSec = () => {
    if (Object.keys(runningStatus).length) {
      setRunningStatus((prevData) => {
        const updatedData = { ...prevData }; // Create a shallow copy of the previous state
        for (let key of graph) {
          // console.log("key.sensor_data" , updatedData, key.machine_id)
          if (updatedData[key.machine_id].machine_current_state === '2') {
            const timeObject = new Date(
              `2000-01-01T${updatedData[key.machine_id].on_time}`
            );
            timeObject.setSeconds(timeObject.getSeconds() + 1);
            const newTimeString = timeObject.toLocaleTimeString('en-US', {
              hour12: false,
            });

            // Create a new object for the specific item and update the on_time property
            updatedData[key.machine_id] = {
              ...updatedData[key.machine_id],
              on_time: newTimeString,
            };
          }
        }
        return updatedData;
      });
    }

  }


  const machineStatus = (mech_id) => {
    // console.log(mech_id, runningStatus[mech_id],)
    if (Object.keys(runningStatus).length) {
      if (runningStatus[mech_id].machine_current_status === "D") {
        return (
          <div style={{ color: "blue", backgroundColor: 'blue', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
      else {
        if (runningStatus[mech_id].machine_current_state === "0") {
          return (
            <div style={{ color: "red", backgroundColor: 'red', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
          )
        }
        else if (runningStatus[mech_id].machine_current_state === "1") {
          return (
            <div style={{ color: "orange", backgroundColor: 'orange', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
          )
        }
        else {
          return (
            <div style={{ color: "green", backgroundColor: 'green', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
          )
        }
      }
    }


  }

  const NavigateToLogs = (item) => {
    console.log("NavigateToLogs", item)
    setSensorLog(JSON.stringify(item))

    let temp = {
      on_time: runningStatus[item.machine_id].on_time ?? 0,
      efficiency: runningStatus[item.machine_id].efficiency ?? 0,
      mcs: runningStatus[item.machine_id].machine_current_state ?? 0
    }

    setSensorOnTime(JSON.stringify(temp))
    navigate("/machineLogs");

  }

  const ButtonColor = (mech_id, data) => {
    if (runningStatus[mech_id]) {
      if (runningStatus[mech_id].machine_current_status == "D") {
        return ("#1E2A7A") //gray
      }
      else {
        if (runningStatus[mech_id].efficiency >= data.Y) {
          return ("#8FCD71") //green
        }
        else if (runningStatus[mech_id].efficiency >= data.R) {
          return ("#F6E069") // orange
        }
        else {
          return ("#F97171") // red
        }
      }
    }
    else {
      return ("#D3D3D3") //gray
    }
  }


  const showShift = (machine_id) => {
    const time = moment(runningStatus[machine_id].start_time, 'HH:mm:ss')
    const start = time.format('HH:mm');


    const time1 = moment(runningStatus[machine_id].end_time, 'HH:mm:ss')
    const end = time1.format('HH:mm');

    return (start + " - " + end)

  }

  const PrevshowShift = (machine_id) => {
    const time = moment(runningStatus[machine_id].previousData.shift_start_time)
    const start = time.format('HH:mm');


    const time1 = moment(runningStatus[machine_id].previousData.shift_end_time)
    const end = time1.format('HH:mm');

    // console.log("start time - end time", start,end, time, time1)
    return (start + " - " + end)

  }


  clearInterval(intervalId);
  intervalId = setInterval(() => {
    updateOnTimeInSec();
  }, 1000);

  const errortimer = () => {
    setError(undefined)
  }

  const handleLogout = () => {
    removeUserDetails()
    localStorage.clear();
    // console.log("logout")
    navigate("/WebLogin");
  }

  const receiveDataFromChild = (data) => {
    setDownloadExcel(false);
  };

  useEffect(() => {
    handleFetchMachineList()
    return () => {
      clearInterval(runtimeid);
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);


  return (
    <div className='main'>

      <div className='Admin_menu'>
        <div className='TvDashboard'>

          <Header show={1} />
          <div style={{ display: "flex", flexDirection: "row", position: "absolute", top: 10, right: 10, }} >
            <i style={{ padding: 10, margin: "0", fontSize: 20, color: "#254e6f" }} class="fa fa-download" onClick={() => setDownloadExcel(true)}></i>
            <i style={{ padding: 10, margin: "0 10px", fontSize: 20, color: "#254e6f" }} class="fa fa-sign-out" onClick={handleLogout}></i>
          </div>

          <div className='com_name_mobile'>
            <h3>SuperManager</h3>
          </div>

          <div className='machines'>

            {/* {graph.length ? */}
            <div style={{
              width: "100%",
              flexWrap: "wrap",
              display: "inline-flex",
              justifyContent: "space-around",
              height: "80%",
            }}>
              {((Object.keys(runningStatus).length)) ?
                graph.map((item, index) => {
                  return (
                    <div key={index} className='machine_card' style={{ backgroundColor: machineTime(item.machine_id, item.sensor_data.alarm_color) }}>
                      {runningStatus[item.machine_id] !== undefined ?
                        (
                          ((runningStatus[item.machine_id].shift_status) ?
                            <div>
                              <div style={{ paddingHorizontal: "2%" }}>
                                {/* first row */}
                                <div style={{ paddingTop: 5, marginLeft: 5 }} className='inLine'>
                                  {((Object.keys(runningStatus).length) !== 0 && (runningStatus[item.machine_id])) ? (machineStatus(item.machine_id)) : ("")}

                                  <div style={{ marginLeft: 20 }}>
                                    <h4 className='TextMachName'>{item.sensor_name}</h4>
                                    <h5 className='TextDevName'>{item.device_name}</h5>
                                  </div>
                                </div>

                                {/* 2nd row */}

                                {item.sensor_data.counter_flag === "Y" ?
                                  <div style={{ justifyContent: "space-between" }} className='inLine'>
                                    {runningStatus.hasOwnProperty(item.machine_id) ?
                                      <h3 className='machineTimeText'>{(runningStatus[item.machine_id].on_time)}</h3>
                                      :
                                      <h3 className='machineTimeText'>--:--</h3>
                                    }

                                    <h4 style={{ width: "40%", flexWrap: 'wrap' }} className='machineTimeText1'>{runningStatus[item.machine_id].sensor_count} {runningStatus[item.machine_id].counterUnit}</h4>

                                    <h4 className='machineEff'>
                                      {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].efficiency) : ("NA")}%
                                    </h4>

                                  </div>
                                  :
                                  <div style={{ justifyContent: "space-between" }} className='inLine'>

                                    {runningStatus.hasOwnProperty(item.machine_id) ?
                                      <h3 className='machineTimeText'>{(runningStatus[item.machine_id].on_time)}</h3>
                                      :
                                      <h3 className='machineTimeText'>--:--</h3>
                                    }
                                    <h4 className='machineEff'>
                                      {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].efficiency) : ("NA")}%
                                    </h4>
                                  </div>
                                }

                                <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                                  {(Object.keys(colorWidthData).length && (colorWidthData[item.machine_id])) ?
                                    colorWidthData[item.machine_id].map(({ color, width }, index) => (
                                      <div key={index} style={{ width, backgroundColor: color, borderRadius: (borderRadius(index, item.machine_id)) }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.machine_id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                                    )) :
                                    <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                                </div>


                                {/* 3rd row */}

                                <div>
                                  <div style={{ margin: "5px" }} className='inLine'>
                                    <h4 className='shiftTime'>Current Shift</h4>
                                    <h5 className="shiftTimedata" style={{ margin: "0px 10px", color: "#49454F" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? showShift(item.machine_id) : ("NA")} </h5>
                                  </div>
                                </div>

                                <div style={{ justifyContent: "space-between", marginBottom: 10 }} className='inLine'>
                                  <div style={{ marginHorizontal: 10 }}>
                                    {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id]) && (runningStatus[item.machine_id].previousData.machine_time_id !== 0)) ?
                                      <div>
                                        <h4 className='shiftTime'>Previous Shift</h4>
                                        <div className='inLine'>
                                          <h5 className="shiftTimedata" style={{ color: "#938F99" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? PrevshowShift(item.machine_id) : ("NA")}  /  </h5>
                                          <h5 className="shiftTimedata" style={{ color: "#938F99" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].previousData.eff) : ("NA")} %</h5>
                                        </div>
                                      </div>
                                      :
                                      <></>
                                    }
                                  </div>

                                  <div style={{ backgroundColor: ButtonColor(item.machine_id, item.sensor_data.alarm_color), cursor: "pointer" }} className="DetailButton" onClick={() => {  NavigateToLogs(item) }}>
                                    <h4 className="buttonText">
                                      Live Data
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            <div style={{ width: "300px" }}>
                              <div style={{ width: "100%", flexDirection: 'row', alignItems: "center" }}>
                                <h4 style={{ width: "90%", fontSize: 21, fontFamily: "poppins, san-sarif", color: "black", textAlign: "center" }}>{item.sensor_name}</h4>


                              </div>
                              <div style={{
                                width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
                              }}>
                                <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955' }}>{item.device_name}</h4>
                                <div style={{
                                  flexDirection: "row", alignItems: "center",
                                }}>
                                  <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955', paddingRight: 10 }}> Current Shift Not Exist</h4>
                                </div>
                              </div>
                            </div>
                          )

                        ) :
                        (
                          <div style={{ width: "300px" }}>
                            <div style={{ width: "100%", flexDirection: 'row', alignItems: "center" }}>
                              <h4 style={{ width: "90%", fontSize: 21, fontFamily: "poppins, san-sarif", color: "black", textAlign: "center" }}>{item.sensor_name}</h4>


                            </div>
                            <div style={{
                              width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
                            }}>
                              <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955' }}>{item.device_name}</h4>
                              <div style={{
                                flexDirection: "row", alignItems: "center",
                              }}>
                                <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955', paddingRight: 10 }}> Current Shift Not Exist</h4>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  )
                })
                :
                <div style={{ width: "95%", margin: '50px 2.5%', justifyContent: "space-between", display: "inline-flex", flexDirection: "row" }}>
                  {/* {listStatus ?
                    <h3>Machines not added</h3> : */}
                    <>{[...Array(3)].map((_, index) => (
                      <div style={{ width: "25%" }} key={index}>
                        <Skeleton style={{ height: 150 }} />
                      </div>
                    ))}</>
                    {/* } */}
                </div>
              }


            </div>
            {/* :
              (
                <div style={{ width: "98%", marginTop: '50px', justifyContent: "space-between", display: "inline-flex", flexDirection: "row" }}>
                  {[...Array(3)].map((_, index) => (
                    <div style={{ width: "25%" }} key={index}>
                      <Skeleton style={{ height: 150 }} />
                    </div>
                  ))}
                </div>
              )

            } */}

          </div>


        </div>


        <div>
          <p className='error'>{error}</p>
        </div>
        {/* <Modal animationType="slide" transparent={true} visible={downloadExcel}>
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <Text style={{ color: "black", margin: 10, fontSize: 18 }}>Download Report </Text>
                  <Icon name="alpha-x" size={35} color="black" onPress={() => setDownloadExcel(false)} />
                </View>

                <Report show={4} deviceData={gb_machine_id.current.replace(/'/g, '').replace(/\s/g, '')} sendDataToParent={receiveDataFromChild} email={false} />
              </View>
            </View>
          </Modal> */}

        <Modal isOpen={downloadExcel}>
          <div>
            <div className='report_download'>
              <h3>Report Download</h3>
              <i className="fa-solid fa-xmark" onClick={() => setDownloadExcel(false)}></i>
            </div>
            <Report show={4} deviceData={gb_machine_id.current.replace(/'/g, '').replace(/\s/g, '')} sendDataToParent={receiveDataFromChild} email={false} />
          </div>
        </Modal>
      </div>
    </div>
  )
}

export default Owner