// Modal.js

// import React from "react";
// import "../Styles/UserCard.css"

// const Modal = ({ isOpen, onClose, children }) => {
// 	if (!isOpen) return null;

// 	return (
// 		<div
// 			onClick={onClose}
// 			style={{
// 				position: "fixed",
// 				top: 0,
// 				left: 0,
// 				width: "100%",
// 				height: "100%",
// 				background: "rgba(0, 0, 0, 0.5)",
// 				display: "flex",
// 				alignItems: "center",
// 				justifyContent: "center",
// 			}}
// 		>
// 			<div className="modalBlock"
// 				style={{
// 					background: "white",
// 					margin: "auto",
// 					padding: "2%",
// 					border: "2px solid #000",
// 					borderRadius: "10px",
// 					boxShadow: "2px solid black",
// 					maxHeight: "70vh",
// 					// overflowY: "scroll",
// 					scrollbarWidth:"none"
// 				}}
// 			>
// 				{children}
// 			</div>
// 		</div>
// 	);
// };

// export default Modal;


import React, { useEffect, useState } from "react";
import "../Styles/UserCard.css"

const Modal = ({ isOpen, onClose, children }) => {
  const [visible, setVisible] = useState(false);
  const [animationStyle, setAnimationStyle] = useState({});

  useEffect(() => {
    let timer;
    if (isOpen) {
      setVisible(true);
      setAnimationStyle({
        opacity: 1,
        visibility: "visible",
        transition: "opacity 0.3s ease, visibility 0.3s ease",
      });
    } else {
      setAnimationStyle({
        opacity: 0,
        visibility: "hidden",
        transition: "opacity 0.3s ease, visibility 0.3s ease",
      });
      timer = setTimeout(() => setVisible(false), 300); // Delay unmounting
    }
    return () => clearTimeout(timer);
  }, [isOpen]);

  if (!isOpen && !visible) return null;

  return (
    <div
      onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        ...animationStyle,
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
        style={{
          minWidth: 400,
          background: "white",
          padding: "2%",
          border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
		  // height:"fit-content",
          // maxHeight: "70vh",
          // overflowY: "auto",
          scrollbarWidth: "thin", // Hide scrollbar (Firefox)
          WebkitOverflowScrolling: "touch", // Smooth scrolling (iOS)
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
