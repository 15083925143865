import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TvDashboard from "./Components/TvDashboard"
import TvCode from "./Components/TvCode"
import WebLogin from "./WebAppComponents/WebLogin"
import Navbar from "./WebAppComponents/Navbar"
import WebAdmin from "./WebAppComponents/WebAdmin"
import WebDevice from "./WebAppComponents/WebDevice"
import WebSetting from "./WebAppComponents/WebSetting"
import WebUsers from "./WebAppComponents/WebUsers"
import WebSensor from "./WebAppComponents/WebSensor"
import MachineLogs from "./WebAppComponents/MachineLogs"
import DeviceMachines from "./WebAppComponents/DeviceMachines"
import CompanyForm from "./WebAppComponents/CompanyForm"
import ControllerDashboard from "./Components/ControllerNavbarComponents/ControllerDashboard"
import ControllerComp from "./Components/ControllerNavbarComponents/ControllerComp"
import ControllerDevice from "./Components/ControllerNavbarComponents/ControllerDevices"
import ControllerMachine from "./Components/ControllerNavbarComponents/ControllerMachine"
import ControllerUser from "./Components/ControllerNavbarComponents/ControllerUser"
import ControllerActiveUser from "./Components/ControllerNavbarComponents/ControllerActiveUser"
import SessionLogout from "./WebAppComponents/SessionLogout"
import ControllerOtps from "./Components/ControllerNavbarComponents/ControllerOtps"
import TempDashboard from './Temperature/TempDashboard';
import TempLogin from './Temperature/TempLogin';
import TempDetailsLog from './Temperature/TempDetailsLog';
import TempratureDashboard from './Temperature/TempratureDashboard';
import TempratureUser from './Temperature/TempratureUser';
import TempratureStore from './Temperature/TempratureStore';
import TempratureDevice from './Temperature/TempratureDevice';
import TempratureStats from './Temperature/TempratureStats';
import WebHaltReason from './WebAppComponents/WebHaltReason';
import Owner from './WebAppComponents/Owner';
import AddProduct from './WebAppComponents/AddProduct';
const TVList = React.lazy(() => import('./WebAppComponents/TVList'));

const App = () => {
  // console.log ("Domain", window.location.origin)
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={
          <div className='load' style={{ 
            display: "flex", 
            alignItems: "center", 
            justifyContent: "center", 
            height: "100vh",
          }}>
            <img src="https://tmmapi.9930i.com/sm_loader" alt="Computer man" style={{ height: "150px" }} />
          </div>
        }>
          <Routes>
            {
              window.location.origin === 'https://tv.flovation.in' ? 
              
                // window.location.origin === 'http://localhost:3000' ?
                (
                  <>
                    <Route path="/" element={<TvCode />} />
                    <Route path="/dashboard" element={<TvDashboard />} />
                  </>
                )
                :
                (
                  window.location.origin === 'https://temp.flovation.in' ?
                  // (window.location.origin !== 'http://localhost:3000') ?
                    (
                      <>

                        <Route path="/" element={<TempLogin />} />
                        <Route path="/dashboard" element={<TempDashboard />} />
                        {/* <Route path="/" element={<TempratureDashboard />} /> */}
                        <Route path="/tempratureDash" element={<TempratureDashboard />} />
                        <Route path="/UserList" element={<TempratureUser />} />
                        <Route path="/StoreList" element={<TempratureStore />} />
                        <Route path="/DeviceList" element={<TempratureDevice />} />
                        <Route path="/Devicestats" element={<TempratureStats />} />
                        <Route path="/tempDetailsLog" element={<TempDetailsLog />} />
                      </>
                    )
                    :
                    (
                      <>
                        <Route path="/" element={<WebLogin />} />
                        <Route path="/weblogin" element={<WebLogin />} />
                        <Route path="/webAdmin" element={<WebAdmin />} />
                        <Route path="/navbar" element={<Navbar />} />
                        <Route path="/webDevice" element={<WebDevice />} />
                        <Route path="/webSetting" element={<WebSetting />} />
                        <Route path="/webUsers" element={<WebUsers />} />
                        <Route path="/webSensor" element={<WebSensor />} />
                        <Route path="/machineLogs" element={<MachineLogs />} />
                        <Route path="/deviceMachines" element={<DeviceMachines />} />
                        <Route path="/companyForm" element={<CompanyForm />} />
                        <Route path="/controllerDashboard" element={<ControllerDashboard />} />
                        <Route path="/controllerComp" element={<ControllerComp />} />
                        <Route path="/controllerDevice" element={<ControllerDevice />} />
                        <Route path="/controllerMachine" element={<ControllerMachine />} />
                        <Route path="/controllerUser" element={<ControllerUser />} />
                        <Route path="/controllerActiveUser" element={<ControllerActiveUser />} />
                        <Route path="/sessionLogout" element={<SessionLogout />} />
                        <Route path="/controllerOtps" element={<ControllerOtps />} />
                        <Route path="/webHaltReason" element={<WebHaltReason />} />
                        <Route path="/owner" element={<Owner />} />
                        <Route path="/TVList" element={<TVList />} />
                        <Route path='/AddProduct' element={<AddProduct />} />
                      </>
                    )
                )
            }
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
